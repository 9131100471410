import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  FormText,
  ListGroup,
  ListGroupItem
} from "react-bootstrap";
import "./report-problem.scss";
import { Link } from "react-router-dom";
import { routes_constants } from "../../../service/constants";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import UploadDisputeDocument from "../../ModalComponent/Upload.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/reducers";
import { selectContractDetails } from "../../../redux/selectors/contracts.selector";
import { selectSharedDetails } from "../../../redux/selectors";
import { TransactionType } from "../../../types/contract.type";
import API from "../../../service/api.service";
import { showToaster } from "../../../redux/reducers/shared.reducer";
import { Reason, ToasterType, UploadedDoc } from "../../../types";
import { AppDispatch } from "../../../redux/store";
import { reasonsList } from "../../../redux/actions";
import CustomMessage from "../../ModalComponent/CustomMessage";
import { contractList } from "../../../redux/actions/contracts.action";

interface FormData {
  contract_id: number;
  charge_id: string;
  payment_type_enum: number;
  patient_email: string;
  patient_mobile: number;
  doc_ids: string;
  reason_id: number;
  reason: string;
  termsAccepted: boolean;
}

const validationSchema = Yup.object().shape({
  contract_id: Yup.number()
  .transform((value, originalValue) => (originalValue.trim() === "" ? null : value))
  .nullable() // Allows null values to pass validation before the `required` check
  .required("Contract ID is required."),
  charge_id: Yup.string().required("Transaction is required."),
  payment_type_enum: Yup.string().required("Payment Type is required."),
  reason_id: Yup.number()
  .transform((value, originalValue) => (originalValue.trim() === "" ? null : value))
  .nullable() // Allows null values to pass validation before the `required` check
  .required("Reason is required."),
  patient_email: Yup.string().email("Invalid email format").required("Email is required."),
  patient_mobile: Yup.string()
  .matches(/^\d+$/, "Phone number must contain only digits")
  .min(10, "Phone number must be at least 10 digits")
  .max(15, "Phone number must not exceed 15 digits")
  .required("Phone number is required."),
  reason: Yup.string().max(500, "Message cannot exceed 500 characters."),
  termsAccepted: Yup.boolean()
    .oneOf([true], "You must accept the Terms & Conditions.")
    .required("Terms & Conditions must be accepted."),
});

const ReportProblemComponent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  // const [customMessage, setCustomMessage] = useState(false);
  const [success_message, setSuccessMessage] = useState('');
  const [uploadDocComp, setUploadDocComp] = useState(false);
  const [charge_id, setChargeId] = useState<string>();
  const [payment_type, setPaymentType] = useState<number | null>();
  const [contract_id, setContractId] = useState<number | null>();
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [uploadedDoc, setUploadedDoc] = useState<UploadedDoc[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  const { contracts } = useSelector((state: RootState) => selectContractDetails(state));
  const { reasonList } = useSelector((state: RootState) => selectSharedDetails(state));

  const {
    control,
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (!reasonList.length) {
      dispatch(reasonsList({ limit: 100, offset: 0 }));
    }

    if (!contracts.length) {
      dispatch(contractList({ data: {limit: 100, offset: 0} }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data: FormData) => {
    setLoading(true);

    const doc_ids = uploadedDoc.map(item => item.doc_id).join(',');

    const payload = { ...data, doc_ids, payment_type };
    const response = await API({
        api: `support/add_customer_dispute`,
        body: payload,
        type: 'POST'
    });

    setLoading(false);
    if (response.is_error && response.is_error === 1) {
        dispatch(showToaster({...response, message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true}))
    }
    else if(response.ticket_id){
        const success_message = `Your Concern has been successfully submitted.
                Our customer support team will be contacting you soon.
                Your Case Number is ${response.ticket_id}. Please save it for future reference.`;
        setSuccessMessage(success_message);
        // setCustomMessage(true);
        console.log(success_message);
        reset();
        // dispatch(showToaster({ message: "Thansk for reporting to us!", type: ToasterType.Success, status: true}));
    }

  };

  const selectContractId = async (contract_id: number) => {
    setValue('payment_type_enum', "");
    setValue('charge_id', '');
    setPaymentType(null);
    setChargeId(''); setContractId(null);

    if(contract_id){
      setContractId(contract_id);
      setLoading(true);
      const response = await API({
        api: `details/get_paid_payments?contract_id=${contract_id}`,
        type: 'GET'
      });
      setLoading(false);

      if (response.is_error && response.is_error === 1) {
        dispatch(showToaster({ message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true }));
      }

      let result = (response?.payments && response.payments.length) ? response.payments : [];
      setTransactions(result);

      if (response.is_error && response.is_error === 1) {
          dispatch(showToaster({...response, message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true}))
      }
    }    
  }

  const selectPayment = (charge_id: string) => {
    setChargeId(charge_id);
    const type = transactions.find((t) => t.charge_id === charge_id);
    setPaymentType(type?.payment_type);
    setValue('payment_type_enum', type?.payment_type_enum);
  }

  const setDocument = (doc: UploadedDoc) => {
    setUploadDocComp(false);
    setUploadedDoc(uploadedDoc.concat(doc));
  }

  const removeUpload = async (doc_id: number) => {    
    setLoading(true);
    const response = await API({
      api: `support/remove_dispute_documents`,
      body: { doc_id },
      type: 'POST'
    });
    setLoading(false);

    if(response.flag){
      if (response.is_error && response.is_error === 1) {
        dispatch(showToaster({ message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true }));
      }
      else{
        const newDocs = uploadedDoc.filter(doc => doc.doc_id !== doc_id);
        setUploadedDoc(newDocs);
        setContractId(contract_id);
      }
    }
    else{
      dispatch(showToaster({ message: "Unable to delete, please try again!", type: ToasterType.Warning, status: true }));

    }
  }

  return (
    <>
      {(uploadDocComp && charge_id && contract_id) && (
        <UploadDisputeDocument setDocument={setDocument} isActive={uploadDocComp} handleClose={setUploadDocComp} charge_id={charge_id} contract_id={contract_id}  />
      )}
      
      <div className="support-wrapper vh-100 overflow-hidden">
        <div className="support-header hw-65 w-100 d-flex align-items-center justify-content-between border-bottom px-4 py-2">
          <img src="../../assets/images/FM-logo.svg" alt="" />
          <img src="../../assets/images/bell-icon.png" alt="" />
        </div>
        <div className="support-content w-100  h-100 p-3">
              <div className="d-flex align-items-center mb-4">
                <img
                  src="../../assets/images/back-circle.png"
                  className="hw-40 rounded-circle me-3"
                  alt=""
                />
                <Link to={routes_constants.support} className="decoration-none">
                  <p className="fs-16 fw-700 m-0 text-secondary">
                    Back to help
                  </p>
                </Link>
              </div>
            <div className="support-inner h-100 overflow-auto hide-scroll-bar">
            {(success_message) ? (
              <p>{success_message}</p>
            ) : (

            <Container className="mt-5">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Contract ID</Form.Label>
                    <Form.Select {...register("contract_id")} onChange={(e) => selectContractId(parseInt(e.target?.value))}>
                      <option value="">-- Select --</option>
                      {(contracts && contracts.length) && contracts.map((contract) => (
                          <option value={contract.contract_id}>{contract?.contract_code}</option>
                      ))}
                    </Form.Select>
                    <Form.Text className="text-danger">{(errors.contract_id?.message as string)}</Form.Text>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Transaction</Form.Label>
                    <Form.Select {...register("charge_id")} onChange={(e) => selectPayment(e.target.value)} disabled={!transactions.length}>
                      <option value="">-- {loading ? 'Loading' : 'Select'} --</option>
                      {transactions && transactions.map((transaction: TransactionType) => (
                          <option value={transaction.charge_id}>{transaction?.last_updated}</option>
                      ))}
                    </Form.Select>
                    <Form.Text className="text-danger">{errors.charge_id?.message as string}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Payment Type</Form.Label>
                    <Form.Control type="text" placeholder="Payment type" {...register("payment_type_enum")} disabled={!charge_id} />
                    <Form.Text className="text-danger">{errors.payment_type_enum?.message as string}</Form.Text>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group className="mb-3">
                    <Form.Label>Select Reason</Form.Label>
                    <Form.Select {...register("reason_id")}>
                      <option value="">-- Select --</option>
                      {reasonList && reasonList.map((reason: Reason) => (
                          <option value={reason.id}>{reason?.reason}</option>
                      ))}
                    </Form.Select>
                    <Form.Text className="text-danger">{errors.reason_id?.message as string}</Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter email" {...register("patient_email")} />
                <Form.Text className="text-danger">{errors.patient_email?.message as string}</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control type="Number" placeholder="Enter phone number" {...register("patient_mobile")} />
                <Form.Text className="text-danger">{errors.patient_mobile?.message as string}</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
              <Form.Label>Upload Supporting Documents</Form.Label><br />
              <Form.Text>Please upload clear copy of document</Form.Text><br />
                {charge_id && contract_id && (
                <Button onClick={() =>setUploadDocComp(true)}>Upload</Button>
                )}
                <ListGroup>
                  {uploadedDoc && uploadedDoc.map((doc: UploadedDoc) => (
                    <ListGroupItem>
                      <p>{doc.file_name}</p>
                      {loading ? 'Loading...' : (
                        <img src="../../assets/images/cross-circle.png" alt="Close" className="float-end" onClick={() => removeUpload(doc.doc_id)} />
                      )}
                      
                    </ListGroupItem>
                  ))}
                  
                </ListGroup>
                <Form.Text className="text-danger">{errors.doc_ids?.message as string}</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Message</Form.Label>
                <Form.Control as="textarea" rows={3} {...register("reason")} />
                <Form.Text className="text-danger">{errors.reason?.message as string}</Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="termsAccepted">
                <Form.Check
                  type="checkbox"
                  label="By clicking this box, you agree to our Terms & Conditions."
                  {...register("termsAccepted")}
                />
                <Form.Text className="text-danger">{errors.termsAccepted?.message as string || ''}</Form.Text>
              </Form.Group>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Container>
             )}
            </div>
        </div>
      </div>
     
    </>
  );
};

export default ReportProblemComponent;
