import { combineReducers } from 'redux';
import authReducer from './auth.reducer'; // Ensure this path is correct
import contractReducer from './contracts.reducer';
import paymentsReducer from './payment.reducer';
import registerReducer from './register.reducer';
import sharedReducer from './shared.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  contracts: contractReducer,
  register: registerReducer,
  payment: paymentsReducer,
  shared: sharedReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
