import { Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import { AppDispatch } from "../../../../redux/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import API from "../../../../service/api.service";
import { showToaster } from "../../../../redux/reducers/shared.reducer";
import { ToasterType } from "../../../../types";
import { crypto } from "../../../../service/common";
import { RootState } from "../../../../redux/reducers";
import { selectAuthDetails } from "../../../../redux/selectors";
import "../my-profile.scss";
import { useState } from "react";
import { Link } from "react-router-dom";
import { routes_constants } from "../../../../service/constants";

interface ChangePasswordFormValues {
  current_password: string;
  password: string;
  confirm_password: string;
}

// Define the Yup validation schema
const validationSchema = Yup.object().shape({
  current_password: Yup.string().required('Current password is required'),
  password: Yup.string().required('Password is required'),
  confirm_password: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password'), ''], 'New Password and Confirm Password must be match...'),
});

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { patient_id } = useSelector((state: RootState) => selectAuthDetails(state));

  const { register, handleSubmit, formState: { errors }, setValue } = useForm<ChangePasswordFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      current_password: '',
      password: '',
      confirm_password: ''
    }
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    const response = await API({
      api: `auth/change_password`,
      body: { patient_id: `${patient_id}`, new_password: crypto(data.password), current_password: crypto(data.current_password) },
      type: 'POST'
    });
    setLoading(false);

    if (response.is_error && response.is_error === 1) {
      console.log(response.original_text);
      dispatch(showToaster({message: response.original_text, type: ToasterType.Danger, status: true}))
    }
    else{
      dispatch(showToaster({ message: "Password changed succesfully!", type: ToasterType.Success, status: true}))
    }
    setValue('current_password', '');
    setValue('password', '');
    setValue('confirm_password', '');
  };

  return (
    <>
      <>
        <div className="px-5 mxw-600">
          <h4 className="fs-20 fw-600 m-0">Change Password</h4>
          <p className="fs-16 fw-400 m-0 mb-4">
          For better protection, avoid using a previously used password.
          </p>

          <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            {/* Current Password */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label className="fs-14 fw-500 text-muted">Current Password</Form.Label>
                <Form.Control
                  type="password"
                  {...register('current_password')}
                  isInvalid={!!errors.current_password}
                  placeholder="Enter your current password"
                  className="input_field"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.current_password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {/* New Password */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label className="fs-14 fw-500 text-muted">New Password</Form.Label>
                <Form.Control
                  type="password"
                  {...register('password')}
                  isInvalid={!!errors.password}
                  placeholder="Enter new password"
                  className="input_field"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            {/* Confirm Password */}
            <Col xs={12} className="mb-3">
              <Form.Group>
                <Form.Label className="fs-14 fw-500 text-muted">Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  {...register('confirm_password')}
                  isInvalid={!!errors.confirm_password}
                  placeholder="Confirm Password"
                  className="input_field"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirm_password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Buttons */}
          <div className="d-flex align-items-center">
            <Button variant="secondary" type="submit" className="mnw-125 py-2 me-3" disabled={loading}>
              {loading ? 'Updating...' : 'Save'}
            </Button>
            <Link to={routes_constants.dashboard}>
              <Button variant="outline-secondary" className="mnw-125 py-2">
                Cancel
              </Button>
            </Link>
          </div>
        </Form>
        </div>
      </>
    </>
  );
};
