import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { StatusType, ToasterType } from "../../types";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { selectAuthDetails } from "../../redux/selectors";
import API from "../../service/api.service";
import * as yup from "yup";
import { AppDispatch } from "../../redux/store";
import { showToaster } from "../../redux/reducers/shared.reducer";
import { routes_constants } from "../../service/constants";
import { useState } from "react";

const validationSchema = yup.object().shape({
    message: yup.string().required("Message is required"),
    terms: yup.boolean().oneOf([true], "You must agree to the Terms & Conditions"),
});

interface WriteToUsProps {
    isActive: boolean;
    // handleShow: (args: PaymentParams) => void,
    handleClose: (arg: boolean) => void
}

const WriteToUs = ({ isActive, handleClose }: WriteToUsProps) => {    
    const [loading, setLoading] = useState(false);
    const { user } = useSelector((state: RootState) => selectAuthDetails(state));

    const { register, handleSubmit, formState: { errors } } = useForm<any>({
        resolver: yupResolver(validationSchema)
    });
    
    const dispatch = useDispatch<AppDispatch>();

    const onSubmit = async (data: any) => {
        setLoading(true);
        const payload = {
            action_type: 'customer_write_to_fm',
            patient_mobile: data.mobile,
            message: data.message,
            reason_id: data.reason_id,
            patient_mobile_country_code: user?.patient_mobile_country_code,
            type: '2',
            name: `${user?.patient_first_name} ${user?.patient_last_name}`,
            phone: user?.patient_mobile,
            phone_country_code: user?.patient_mobile_country_code,
            src_url: `Inquiry made from Customer Panel ${routes_constants.basic_url}`,
            speciality: "N.A.",
            zip: user?.zip || '',
            city: user?.city || '',
            state: user?.state || '',
            location_id: user?.city_id || '',
            address: user?.patient_address || '',
            email: user?.patient_email
        }

        const response = await API({
            api: `support/add_wp_contact_query`,
            body: payload,
            type: 'POST'
        });

        setLoading(false);

        if (response.is_error && response.is_error === 1) {
            dispatch(showToaster({...response, message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true}))
        }
        else{
            dispatch(showToaster({ message: "Thansk for writing to us!", type: ToasterType.Success, status: true}));
            handleClose(false);
        }
    };

    return (
        <Modal show={isActive} onHide={() => handleClose(false)} >
            <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4">
            <Modal.Header className="p-0 border-0 d-flex justify-content-between align-items-start">
                <h4 className="fs-18 fw-700 m-0">
                Write to Finance Mutual
                </h4>
              <img src="../../assets/images/cross-circle.png" alt="" className="hw-30" />
            </Modal.Header>
            <Modal.Body className="p-0 mb-4">   
             
              {/* <label className="fs-14 400 m-0 w-100">Message</label> */}
              {/* <textarea name="" id="" className="input_field mb-4 w-100"></textarea> */}
              <Form.Group controlId="message">
                    <Form.Label className="fs-14 400 m-0 w-100">Message</Form.Label>
                    <Form.Control
                        className="input_field mb-4 w-100"
                        as="textarea"
                        placeholder="Enter your name"
                        isInvalid={!!errors.message}
                        {...register("message")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors.name?.message as string)|| ''}
                    </Form.Control.Feedback>
                </Form.Group>
            
            {/* Terms & Conditions */}
            <Form.Group className="mb-3" controlId="terms">
                    <Form.Check
                        type="checkbox"
                        label="By clicking this box, you agree to our Terms & Conditions."
                        isInvalid={!!errors.terms}
                        {...register("terms")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors.terms?.message as string)|| ''}
                    </Form.Control.Feedback>
                    </Form.Group>
            </Modal.Body>

            <Modal.Footer className="border-0 justify-content-start p-0">
                <button className="btn btn-secondary" disabled={loading}>
                Submit
                </button>
            </Modal.Footer>
            </div>
            </Form>
        </Modal>
    );
};

export default WriteToUs;