import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StatusType } from '../../types/common.type';
import { AuthResponse, CustomerFullProfile, CustomerProfile, } from '../../types';
import { authenticate, AuthRejectResponse, logout, LogoutResponse } from '../actions/auth.action';
import { routes_constants } from '../../service/constants';
import { resetStore } from '../actions/global.action';
interface AuthInitialState {
  access_token: string | null;
  reset_password_token: string | null;
  patient_email: string | null;
  isAuthenticated: boolean;
  customer: CustomerProfile | null;
  status: StatusType;
  error?: string | null;
  flag?: number | null;
  reset_current_password: string;
  toaster: boolean;
}

const initialState: AuthInitialState = {
  access_token: null,
  reset_password_token: null,
  reset_current_password: '',
  isAuthenticated: false,
  customer: null,
  status: StatusType.Idle,
  error: '',
  flag: null,
  toaster: false
} as AuthInitialState;

const authReducer = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearAuthState: (state) => {
      return state = initialState;
    },
    loginAfterResetPass: (state, action: PayloadAction<CustomerFullProfile>) => {

      state.access_token = action.payload.access_token;
      state.customer = {
        patient_id: action.payload.patient_id,
          patient_first_name: action.payload.patient_first_name,
          patient_last_name: action.payload.patient_last_name,
          cust_code: action.payload.cust_code,
          date_of_birth: action.payload.date_of_birth,
          patient_code: action.payload.patient_code,
          patient_email: action.payload.patient_email,
          dl_number: action.payload.dl_number,
          country_name: action.payload.country_name,
          city: action.payload.city,
          city_id: action.payload.city_id,
          zip: action.payload.zip,
          state: action.payload.state,
          patient_address: action.payload.patient_address,
          patient_mobile_country_code: action.payload.patient_mobile_country_code,
          patient_mobile: action.payload.patient_mobile,
          patient_ssn: action.payload.patient_ssn,
          patient_image: action.payload.patient_image,
          country_id: action.payload.country_id
      }
      state.isAuthenticated = true;
      state.reset_password_token = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(authenticate.pending, (state) => {
        state.status = StatusType.Loading;
      })
      .addCase(authenticate.fulfilled, (state, action: PayloadAction<AuthResponse>) => {
        state.status = StatusType.Succeeded;

        console.log('Payload::', action.payload.reset_current_password);
        if (typeof action.payload.reset_password_token === 'string' && action.payload.reset_password_token.trim() !== '') {
          state.reset_password_token = action.payload.reset_password_token;
          state.reset_current_password = action.payload.reset_current_password || '';
          state.patient_email = action.payload.patient_email;
        }
        else {
          state.access_token = action.payload.access_token;
          state.customer = {
            patient_id: action.payload.patient_id,
            patient_first_name: action.payload.patient_first_name,
            patient_last_name: action.payload.patient_last_name,
            cust_code: action.payload.cust_code,
            date_of_birth: action.payload.date_of_birth,
            patient_code: action.payload.patient_code,
            patient_email: action.payload.patient_email,
            dl_number: action.payload.dl_number,
            country_name: action.payload.country_name,
            city: action.payload.city,
            city_id: action.payload.city_id,
            zip: action.payload.zip,
            state: action.payload.state,
            patient_address: action.payload.patient_address,
            patient_mobile_country_code: action.payload.patient_mobile_country_code,
            patient_mobile: action.payload.patient_mobile,
            patient_ssn: action.payload.patient_ssn,
            patient_image: action.payload.patient_image,
            country_id: action.payload.country_id
          }
          state.isAuthenticated = true;
        }
      })
      .addCase(authenticate.rejected, (state, action: PayloadAction<AuthRejectResponse | undefined>) => {
        state.status = StatusType.Failed;
        state.error = action?.payload?.message || 'Failed to fetch auth details';
        state.flag = action?.payload?.flag || 401;

        if (action.payload?.flag === 403){
          state.toaster = true;
        }
      })
      .addCase(logout.pending, (state) => {
        state.status = StatusType.Idle;
      })      
      .addCase(logout.fulfilled, (state, action: PayloadAction<LogoutResponse>) => {
        state.status = StatusType.Succeeded;
        localStorage.removeItem('persist:root'); // Clear the persisted data
        // await persistor.purge();
        // state = initialState;
        state = { ...state, isAuthenticated: false };
        console.log(state,'OKAY::');
      })
      .addCase(logout.rejected, (state, action: PayloadAction<AuthRejectResponse | undefined>) => {
        state.status = StatusType.Failed;
        state.error = action?.payload?.message || 'Failed to logout';
        state.flag = action?.payload?.flag || 401;
      });      
  },
});

export const { clearAuthState, loginAfterResetPass } = authReducer.actions;
export default authReducer.reducer;