import React, { useState, useEffect } from 'react';
import './notify.scss';

interface AlertProps {
  message: string;
  type?: 'danger' | 'success' | 'warning' | 'info';
  duration?: number; // duration in milliseconds
  isFade?: false;
}

export const Notify: React.FC<AlertProps> = ({ message, isFade = false, type = 'danger', duration = 3000 }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      if(isFade){
        setVisible(false);
      }
    }, duration);

    return () => clearTimeout(timer);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  return (
    <div className={`alert alert-${type} ${visible ? 'fade-in' : 'fade-out'}`} role="alert">
      {message}
    </div>
  );
};