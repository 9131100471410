//will write some magic here

import { createAsyncThunk } from "@reduxjs/toolkit";
import { FAQs, Pagination, Reason } from "../../types";
import API from "../../service/api.service";

export const sharedAction = {}

export const reasonsList = createAsyncThunk<Reason[], Pagination , { rejectValue: string }>(
    'support/reasons_list',
    async ({}, { rejectWithValue }) => {
      try {         
  
        const response = await API({
          api: `support/fetch_all_callback_reasons?limit=100&offset=0`,
          type: 'GET',
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }
  
        return response.data;      
      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
      }
    }
  );

  interface FaqListInput {
    country_id?: number;
    currency_id?: number;
    type: number;
  }

  export const faqsList = createAsyncThunk<FAQs[], FaqListInput , { rejectValue: string }>(
    'support/faq_list',
    async (input, { rejectWithValue }) => {
      try {         
  
        let queryParams = `country_id=${input.country_id}&type=${input.type}`
        queryParams = input.currency_id ? queryParams + `&currency_id=${input.currency_id}` : queryParams;

        const response = await API({
          api: `support/get_faq?${queryParams}`,
          type: 'GET',
        });
  
        if (response.is_error) {
          return rejectWithValue(response.original_text);
        }
  
        return response.result;
      } catch (err: any) {
        return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
      }
    }
  );