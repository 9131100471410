import { SaveFinancingErrorParams } from "../types";
import API from "./api.service";
import { md5 } from "./md5.service";

export const isEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const crypto = (data: string) => {
    return md5(data);
}

export const saveFinancingError = async (data: SaveFinancingErrorParams) => {
    const tracking_data = {
      contract_id: '',
      split_req_id: '',
      error_message: data.error_message || '',
      error_type: data.error_type || '',
      payment_intent_id: '',
      contract_token: data.contract_token || '',
      patient_id: data.patient_id || '',
      pc_code: data.pc_code || ''
    };

    await API({type: 'POST', api: 'proposal/save_financing_errors', body: tracking_data});
    return true;
}

export const getGoogleAPIKey = () => {
    if (window.location.href.includes('.financemutual.com')) {
        return 'AIzaSyAC-sYjuX1Avr6W_kNtfL06HQtNnWluAvs';
    } else {
        return 'AIzaSyDfsOQdPjaofT7MKQFzlazJn1rnZANi91w'
    }
}

export const fetchAddress = ( data: any, type: string ) => {
    const component = data.address_components.find((comp: any) => comp.types.includes(type));
    return component ? component.long_name : null;
};

export async function fileToBase64(file: File): Promise<ArrayBuffer> {
    const reader = new FileReader();
  
    // Use async/await to handle FileReader events
    const base64String = await new Promise<ArrayBuffer>((resolve, reject) => {
      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as ArrayBuffer);
        } else {
          reject(new Error("File reading failed"));
        }
      };
      reader.onerror = () => reject(reader.error);
      reader.readAsDataURL(file); // Converts file to base64
    });
  
    return base64String;
  }

export async function base64ToBlob(base64String: string) {
  const dataURI = base64String;
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  const blob = new Blob([ab], {
    type: 'image/jpeg'
  });
  return blob;
}