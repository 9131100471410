import React from 'react';
import './shimmerLoader.scss';

export const ShimmerLoader: React.FC = () => {

  return (
    <div className="overlay">
     <div
        className="fm-spinner p-3 d-flex justify-content-center align-items-center rounded-circle"
      >
        <img src="../../assets/images/logo-icon.svg" alt="" className="h-100" />
      </div>
    </div>
  );
};