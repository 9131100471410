import { createSelector } from 'reselect';
import { RootState } from '../reducers';

export const selectContractDetails = createSelector(
  (state: RootState) => state.contracts,
  (contract) => ({
    contracts: contract.contractList,
    selectedContractDetails: contract.contractDetail,
    contractServices: contract.contractServices,
    status: contract.status
  })
);

