import Modal from "react-bootstrap/Modal";
import { Form, Button, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../redux/store";
import { RootState } from "../../redux/reducers";
import { selectAuthDetails, selectSharedDetails } from "../../redux/selectors";
import * as yup from "yup";
import { showToaster } from "../../redux/reducers/shared.reducer";
import API from "../../service/api.service";
import { Reason, StatusType, ToasterType } from "../../types";
import { useEffect, useState } from "react";
import { reasonsList } from "../../redux/actions";

interface SpeakWithExpertProps {
    isActive: boolean;
    // handleShow: (args: PaymentParams) => void,
    handleClose: (arg: boolean) => void
}

type FormData = {
    name: string;
    patient_mobile: string;
    reason: string;
    message: string;
    terms: boolean;
};
  
const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required").min(3, "Name must be at least 3 characters"),
    patient_mobile: yup
        .string()
        .required("Phone Number is required")
        .matches(/^\d{10}$/, "Phone Number must be 10 digits"),
    reason_id: yup.string().required("Please select a reason"),
    message: yup.string().required("Message is required"),
    terms: yup.boolean().oneOf([true], "You must agree to the Terms & Conditions"),
});

const SpeakWithExpert = ({ isActive, handleClose }: SpeakWithExpertProps) => {  
    const [csrReqTz, setCsrReqTz] = useState('');
    const [csrReqTzName, setCsrReqTzName] = useState('');  

    const { user } = useSelector((state: RootState) => selectAuthDetails(state));
    const { reasonList, status } = useSelector((state: RootState) => selectSharedDetails(state));

  
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        const userTimezoneOffset = new Date().getTimezoneOffset() * -1; 
        const userTimezoneHours = Math.floor(userTimezoneOffset / 60);
        const userTimezoneMinutes = userTimezoneOffset % 60;
        const userTimezoneSign = userTimezoneHours < 0 ? '-' : '+';
        const paddedMinutes = Math.abs(userTimezoneMinutes).toString().padStart(2, '0');
        setCsrReqTz(`${userTimezoneSign}${Math.abs(userTimezoneHours)}${paddedMinutes}`);
        const userTimezoneName = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        setCsrReqTzName(userTimezoneName);

        dispatch(reasonsList({ limit: 100, offset: 0 }));
      }, []); 

    
    const { register, handleSubmit, formState: { errors } } = useForm<any>({
        resolver: yupResolver(validationSchema)
      });
    
      const onSubmit = async (data: any) => {
        const today = new Date();
        const call_req_date = today.toLocaleDateString('en-GB'); // Format: DD-MM-YYYY 
        const call_req_time = today.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true }); 

        const payload = {
            patient_mobile: data.mobile,
            message: data.message,
            call_req_date: call_req_date || '',
            call_req_time: call_req_time || '',
            csr_req_tz: csrReqTz || '',
            csr_req_tz_name: csrReqTzName || '',
            reason_id: data.reason_id,
            patient_mobile_country_code: user?.patient_mobile_country_code,
            user_type: "patient"
        }

        console.log({data, payload});


        const response = await API({
          api: `support/add_csr_call_request`,
          body: payload,
          type: 'POST'
        });

        console.log({response});

        if (response.is_error && response.is_error === 1) {
          dispatch(showToaster({...response, message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true}))
        }
        else{
          dispatch(showToaster({ message: "Your request send successfully!", type: ToasterType.Success, status: true}));
          handleClose(false);
        }
      };

    return (
        <Modal show={isActive} onHide={() => handleClose(false)}>
            <Modal.Header className="p-4 pb-0 border-0 d-flex justify-content-between align-items-start">
                <h4 className="fs-18 fw-600 m-0">
                    Speak with an expert
                </h4>
             <img src=" ../../assets/images/cross-circle.png" className="hw-30 cursor" alt="" />
            </Modal.Header>
            <Modal.Body className="pt-0 px-4">
                <Container className="mt-3">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    {/* Name */}
                    <Form.Group className="mb-3" controlId="name">
                    <Form.Label className="fs-14 fw-40 text-muted">Name</Form.Label>
                    <Form.Control 
                    className="input_field"
                        type="text"
                        placeholder="Enter your name"
                        isInvalid={!!errors.name}
                        {...register("name")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors.name?.message as string) || ''}
                    </Form.Control.Feedback>
                    </Form.Group>

                    {/* Phone Number */}
                    <Form.Group className="mb-3" controlId="patient_mobile">
                    <Form.Label className="fs-14 fw-40 text-muted">Phone Number</Form.Label>
                    <Form.Control 
                    className="input_field"
                        type="text"
                        placeholder="Enter your phone number"
                        isInvalid={!!errors.patient_mobile}
                        {...register("patient_mobile")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors.mobile?.message as string) || ''}
                    </Form.Control.Feedback>
                    </Form.Group>

                    {/* Select Reason */}
                    <Form.Group className="mb-3" controlId="reason_id">
                    <Form.Label className="fs-14 fw-40 text-muted">Select Reason</Form.Label>
                    <Form.Select className="input_field" isInvalid={!!errors.reason_id} {...register("reason_id")}>
                        <option value="">Choose...</option>
                        {reasonList && reasonList.map((reason: Reason) => {
                         return (<option value={reason.id}>{reason.reason}</option>)
                        })}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {(errors.reason?.message as string) || ''}
                    </Form.Control.Feedback>
                    </Form.Group>

                    {/* Message */}
                    <Form.Group className="mb-3" controlId="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                        as="textarea"
                        className="input_field"
                        rows={4}
                        placeholder="Enter your message"
                        isInvalid={!!errors.message}
                        {...register("message")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors.message?.message as string)|| ''}
                    </Form.Control.Feedback>
                    </Form.Group>

                    {/* Terms & Conditions */}
                    <Form.Group className="mb-3" controlId="terms">
                    <Form.Check
                        type="checkbox"
                        className="fs-14 fw-400"
                        label="By clicking this box, you agree to our Terms & Conditions."
                        isInvalid={!!errors.terms}
                        {...register("terms")}
                    />
                    <Form.Control.Feedback type="invalid">
                        {(errors.terms?.message as string)|| ''}
                    </Form.Control.Feedback>
                    </Form.Group>

                    {/* Submit Button */}
                    <Button variant="secondary" type="submit" disabled={status !== StatusType.Loading}>
                    Submit
                    </Button>
                </Form>
                </Container>
            </Modal.Body>

            <Modal.Footer className="border-0 justify-content-start p-4">&nbsp;</Modal.Footer>
        </Modal>
    );
};

export default SpeakWithExpert;