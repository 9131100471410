import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Form, Alert, ProgressBar, Button } from "react-bootstrap";
import { ToasterType, UploadedDoc } from "../../types";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import API from "../../service/api.service";
import { AppDispatch } from "../../redux/store";
import { showToaster } from "../../redux/reducers/shared.reducer";
import { base64ToBlob } from "../../service/common";

const validationSchema = Yup.object({
    file: Yup.mixed()
      .required("A file is required")
      .test("fileSize", "File size must be less than 10 MB", (value) => {
        if (!value || !(value instanceof File)) return false;
        return value.size <= 10 * 1024 * 1024; // 10 MB
      })
      .test("fileType", "Only PDF, JPEG, or PNG files are allowed", (value) => {
        if (!value || !(value instanceof File)) return false;
        return ["application/pdf", "image/jpeg", "image/png"].includes(value.type);
      }),
  });

interface FormData {
    file: File | null;
}

interface UploadDocumentProps {
    isActive: boolean;
    handleClose: (arg: boolean) => void,
    setDocument: (arg: UploadedDoc) => void;
    charge_id: string, 
    contract_id: number
}

const UploadDisputeDocument = ({ isActive, handleClose, setDocument, charge_id, contract_id  }: UploadDocumentProps) => {   
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState<any>();
    const [inputKey, setInputKey] = useState(Date.now());

    const dispatch = useDispatch<AppDispatch>();

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
      } = useForm<any>({
        resolver: yupResolver(validationSchema),
        defaultValues: { file: null },
      });
    
      const [uploadProgress, setUploadProgress] = useState<number>(0);
      const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
    
      const uploadDocument = ( data:FormData ) => {
        setLoading(true);
        // Simulate file upload
        setUploadProgress(0);
        const interval = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 100) {
              clearInterval(interval);
              setUploadedFileName(data.file?.name || null);
              if (data.file) {
                const reader = new FileReader();
                reader.onloadend = () => {
                  setPreviewImage(reader.result);
                };
                reader.readAsDataURL(data.file);
              } else {
                setPreviewImage(null);
              }
            }
            return prev + 20;
          });
        }, 500);

        setLoading(false);
      }

      const onSubmit = async (data: FormData) => {
        setLoading(true);
        console.log("Submitted data:", data.file);
        if(!data.file){
          dispatch(showToaster({ message: "File is required!", type: ToasterType.Danger, status: true }));
          return false;
        }

        const document_file = await base64ToBlob(previewImage);

        const payload ={
          document_file,
          contract_id,
          charge_id
        }

        const response = await API({
          api: `support/upload_dispute_documents`,
          body: payload,
          type: 'FORM'
        });

        setLoading(false);

        if (response.is_error && response.is_error === 1) {
            dispatch(showToaster({...response, message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true}))
        }
        else{
          setDocument({ file_name: uploadedFileName || '', doc_id: response.doc_id, doc_view_url: response.doc_view_url });
            // handleClose(false);
        }
      };

    const onFileDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setValue("file", file, { shouldValidate: true });
    console.log('Uploaded here...');
    };

    const removeUpload = () => {
      setUploadedFileName(null);
      setValue("file", null, { shouldValidate: false });
      setInputKey(Date.now());
    }
    
    return (
        <Form onSubmit={(e) => {
            console.log("Form submission triggered");
            handleSubmit(onSubmit)(e);
          }}>
        <Modal show={isActive} onHide={() => handleClose(false)} >
            <div className="p-4">
            <Modal.Header className="p-0 border-0 d-flex justify-content-between align-items-start">
                <h4 className="fs-18 fw-700 m-0">
                Upload Document
                </h4>
              <img src="../../assets/images/cross-circle.png" alt="" className="hw-30" />
            </Modal.Header>
            <Modal.Body className="p-0 mb-4">   
                <Form.Group>
                    <div
                    onDrop={onFileDrop}
                    onDragOver={(e) => e.preventDefault()}
                    style={{
                        border: "2px dashed #007bff",
                        borderRadius: "5px",
                        padding: "20px",
                        textAlign: "center",
                        cursor: "pointer",
                        backgroundColor: "#f8f9fa",
                    }}
                    >
                    <Controller
                        name="file"
                        control={control}
                        key={inputKey}
                        render={({ field }) => (
                        <>
                            <input
                            type="file"
                            id="fileUpload"
                            style={{ display: "none" }}
                            onChange={(e) => {
                              console.log('E:: Comes here::', e);
                                if (e.target.files && e.target.files.length > 0) {
                                    console.log(e.target.files[0]);
                                    field.onChange(e.target.files[0]);
                                    setValue("file", e.target.files[0], { shouldValidate: false });
                                    uploadDocument({ file: e.target.files[0] });
                                }
                            }}
                            />
                            <label htmlFor="fileUpload" style={{ cursor: "pointer" }}>
                            <div>Click or Drag to Upload file</div>
                            <small>(Max file size: 10 MB, supported files: pdf, jpeg, png)</small>
                            </label>
                        </>
                        )}
                    />
                    </div>
                    {errors.file && errors.file.message as string}
                </Form.Group>

                {errors?.file?.message && (
                  <Alert variant="Danger">
                    <strong>{errors?.file?.message as string}</strong>
                  </Alert>
                )}
                {uploadedFileName && (
                    <div className="mt-3">
                    <Alert variant="success">
                        <strong>{uploadedFileName}</strong>
                        <img src="../../assets/images/cross-circle.png" alt="Close" className="float-end" onClick={removeUpload} />
                    </Alert>
                    </div>
                )}

                {uploadProgress > 0 && uploadProgress < 100 && (
                    <ProgressBar animated now={uploadProgress} label={`${uploadProgress}%`} />
                )}

            </Modal.Body>

            <Modal.Footer className="border-0 justify-content-start p-0">                
                <Button variant="btn btn-secondary" type="submit" disabled={loading || !uploadedFileName} onClick={handleSubmit(onSubmit)}>
                  Upload File
                </Button>
            </Modal.Footer>
            </div>
        </Modal>
        </Form>
    );
};

export default UploadDisputeDocument;