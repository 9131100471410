import { createAsyncThunk } from '@reduxjs/toolkit';
import API from '../../service/api.service';
import { ContractDetails } from '../../types/contract.type';
import { ApiResponse, CountryCurrencyInfo } from '../../types';
import { RegisterSteps } from '../../types/register.type';

export interface VerificationTokenResponse extends ApiResponse{
  verification_token: string;
  patient_id: number;
  title: string;
  patient_first_name: string;
  patient_last_name: string;
  from_split: number;
  verification_status: number;
  account_approved: number;
  contract_info: ContractDetails[]
  country_currency_info: CountryCurrencyInfo
}

interface GetVerificationTokenInput {
  email?: string;
  contract_code?: string;
}

export const getVerificationToken = createAsyncThunk<VerificationTokenResponse, GetVerificationTokenInput, { rejectValue: string }>(
  'register/getVerificationToken',
  async (payload, { rejectWithValue }) => {
    try {
      
      if(!payload){
        return rejectWithValue('Please provide contract code!');
      }      

      const response = await API({
        api: `auth/get_verification_token`,
        type: 'POST',
        body: payload
      });

      if (response.is_error) {
        return rejectWithValue(response.original_text);
      }

      return response.data;      
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
    }
  }
);

interface ChecktVerificationTokenInput {
  verification_token: string;
}

export const checkVerificationToken = createAsyncThunk<VerificationTokenResponse, ChecktVerificationTokenInput, { rejectValue: string }>(
  'register/checkVerificationToken',
  async (payload, { rejectWithValue }) => {
    try {
      
      if(!payload){
        return rejectWithValue('Verification token is required!');
      }      

      const response = await API({
        api: `auth/check_verification_token`,
        type: 'POST',
        body: payload
      });

      if (response.is_error) {
        return rejectWithValue(response.original_text);
      }

      return response.data;      
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
    }
  }
);

export interface ValidateCustomerInput {
  verification_token: string;
  date_of_birth?: string;
  // dl_number: string;
  patient_ssn_last4?: string;
  card_last4?: number
}

export const validateCustomer = createAsyncThunk<VerificationTokenResponse, ValidateCustomerInput, { rejectValue: string }>(
  'register/validateCustomer',
  async (payload, { rejectWithValue }) => {
    try {

      if(!payload){
        return rejectWithValue('Verification token is required!');
      }

      const response = await API({
        api: `auth/validate_customer`,
        type: 'POST',
        body: payload
      });

      if (response.is_error) {
        return rejectWithValue(response.original_text);
      }

      return response;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch verification token');
    }
  }
);

export interface SetPasswordInput {
  patient_password: string;
  verification_token: string;
  reset_password_token?: string;
}

export const setPassword = createAsyncThunk<VerificationTokenResponse, SetPasswordInput, { rejectValue: string }>(
  'register/setPassword',
  async (payload, { rejectWithValue }) => {
    try {

      const response = await API({
        api: `auth/setPassword`,
        type: 'POST',
        body: payload
      });

      if (response.is_error) {
        return rejectWithValue(response.original_text);
      }

      return response.data;      
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to set password');
    }
  }
);

export const changeRegisterStep = createAsyncThunk<RegisterSteps, RegisterSteps>(
  'register/changeStep',
  async (step, { rejectWithValue }) => {
    try {
       return step;

    } catch (error) {
      return rejectWithValue(false);
    }
  }
);