import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import GetVerificationLazyComponent from './get-verification/get-verification.lazy';
import { RegisterSteps } from '../../types/register.type';
import ReviewComponent from './review-details/review-details.component';
import VerifyLazyComponent from './validate-contract/validate-contract.lazy';
import SetPasswordLazyComponent from './set-password/set-password.lazy';
import { useNavigate, useParams } from 'react-router-dom';
import { routes_constants } from '../../service/constants';
import { selectAuthDetails } from '../../redux/selectors';
import { AppDispatch } from '../../redux/store';
import { showToaster } from '../../redux/reducers/shared.reducer';
import { ToasterType } from '../../types';
import API from '../../service/api.service';
import { setVerificationDetails } from '../../redux/reducers/register.reducer';

interface ContractValidateComponentProps {}

const ContractValidateComponent: FC<ContractValidateComponentProps> = () => {
  const [loading, setLoading] = useState(false);
  const { registerStep } = useSelector((state: RootState) => state.register);
  const dispatch = useDispatch<AppDispatch>();

  const { contract_code } = useParams<{ contract_code: string }>();
  const { isAuthenticated } = useSelector((state: RootState) => selectAuthDetails(state));
  const navigate = useNavigate();

  useEffect(() => {
    const checkVerifyToken = async () => {
      setLoading(true);
      const input = { contract_code };
      const response = await API({
        api: `auth/get_verification_token`,
        type: 'POST',
        body: input
      });
      setLoading(false);

      if(response?.is_error === 1){
        if(response?.flag === 409){
          dispatch(showToaster({ message: "Your account is already verified, please login!", type: ToasterType.Success, status: true }))
          navigate(routes_constants.login);
        }
        else{
          dispatch(showToaster({ message: response.message || 'Token not valid!', type: ToasterType.Danger, status: true }))
        }
      }
      else if(response?.data?.verification_token){
        if(response?.data?.verification_status === 3){
          dispatch(showToaster({ message: "Your account is already verified, please set your password!", type: ToasterType.Success, status: true }))
        }
        dispatch(setVerificationDetails(response?.data));
      }
      else{
        dispatch(showToaster({ message: response.message || 'Something went wrong! Please contact', type: ToasterType.Danger, status: true }))
      }
    }

    if(isAuthenticated){
      navigate(routes_constants.dashboard);
    }
    else{
      checkVerifyToken();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderComponent = () => {
    switch (registerStep) {
      case RegisterSteps.Step1:
        return <GetVerificationLazyComponent />;
      case RegisterSteps.Step2:
        return <ReviewComponent />;
        case RegisterSteps.Step3://Default is set to step 3 now...
          return <VerifyLazyComponent />;
        case RegisterSteps.Step4:
            return <SetPasswordLazyComponent />;
            default:
        return <GetVerificationLazyComponent />;
    }
  };

 return (
    <>
      {loading ? 'Loading...' : renderComponent()}
    </>
  );
};

export default ContractValidateComponent;