import { useEffect } from 'react'
import { Accordion } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { faqsList } from '../../redux/actions';
import { selectAuthDetails, selectSharedDetails } from '../../redux/selectors';
import { RootState } from '../../redux/reducers';

const FAQComponent = () => {
    const dispatch = useDispatch<AppDispatch>();

    const { user } = useSelector((state: RootState) => selectAuthDetails(state));
    const { faq_list } = useSelector((state: RootState) => selectSharedDetails(state));

    useEffect(() => {
        if(faq_list?.length === 0){
            dispatch(faqsList({ country_id: user?.country_id, type: 2 }));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 

  return (
    <div className="container-xl pt-5">
        <div className="faq-inner">
        <div className="text-center mb-5">
            <h4 className="fs-24 fw-700 mb-3">
            Frequently Asked Questions
            </h4>
            <p className="fs-16 fw-400 m-0 text-muted">
            We have tried out best to solve your queries about
            our product/services. <br /> If we missed something,
            Let us know
            </p>
        </div>
        <div className="faq-body">
            {/* <Accordion defaultActiveKey="0" className="border-0">
            <Accordion.Item
                eventKey="0"
                className="mb-3 border-0"
            >
                <Accordion.Header className="border-bottom">
                Accordion Item #1
                </Accordion.Header>
                <Accordion.Body>
                Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo
                consequat. Duis aute irure dolor in
                reprehenderit in voluptate velit esse cillum
                dolore eu fugiat nulla pariatur. Excepteur sint
                occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum.
                </Accordion.Body>
            </Accordion.Item>
            </Accordion> */}
            <Accordion defaultActiveKey="0" className="border-0">
                {faq_list && faq_list.length ? faq_list.map((faq, index) => (
                <Accordion.Item
                    eventKey={index.toString()}
                    key={index}
                    className="mb-3 border-0"
                >
                    <Accordion.Header className="border-bottom">
                    {faq.question}
                    </Accordion.Header>
                    <Accordion.Body>
                    {faq.answer}
                    </Accordion.Body>
                </Accordion.Item>
                )) : (<p>No FAQs found!</p>)}
            </Accordion>
        </div>
        </div>
    </div>
  )
}

export default FAQComponent