import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Row, Col, Button, Form } from "react-bootstrap";
import { selectAuthDetails } from "../../../redux/selectors";
import { RootState } from "../../../redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup';
import API from "../../../service/api.service";
import { AppDispatch } from "../../../redux/store";
import { showToaster } from "../../../redux/reducers/shared.reducer";
import { Location, ToasterType } from "../../../types";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { base64ToBlob, fetchAddress, getGoogleAPIKey } from "../../../service/common";
import DatePicker from 'react-datepicker';
import './my-profile.scss';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";

// interface ProfileFormValues {
//   patient_first_name: string;
//   patient_last_name: string;
//   date_of_birth: string;
//   city: string;
//   city_id: number;
//   zip: string;
//   state: string;
//   patient_address: string;
//   patient_image: string;
// }

// Define the Yup validation schema
const validationSchema = Yup.object().shape({
  patient_image: Yup.string().optional(),
  patient_first_name: Yup.string().required('First name is required'),
  patient_last_name: Yup.string().required('Last name is required'),
  date_of_birth: Yup.string().required('Date of birth is required'),
  patient_address: Yup.string().required('Address is required'),
  zip: Yup.string().required('ZIP is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
});

export const MyProfile = () => {
  const { user, patient_id } = useSelector((state: RootState) => selectAuthDetails(state));

  const [location, setLocationData] = useState<Location>();

  const [previewImage, setPreviewImage] = useState<any>((user?.patient_image || "../../assets/images/place-holder.png"));
  const [uploadThisImage, setUploadThisImage] = useState<any>();

  const dispatch = useDispatch<AppDispatch>();

  const { control, setValue, register, handleSubmit, formState: { errors } } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      patient_first_name: user?.patient_first_name || "",
      patient_last_name: user?.patient_last_name || "",
      date_of_birth: moment(user?.date_of_birth).format('YYYY/MM/DD'),
      patient_address: user?.patient_address || "",
      zip: location?.zip || user?.zip || "",
      city: location?.city || user?.city || "",
      state: location?.state || user?.state || "",
      patient_image: user?.patient_image || "",
    }
  });

  const onSubmit = async (data: any) => {
    const payload = {
      patient_id, 
      patient_first_name: data.patient_first_name, 
      patient_last_name: data.patient_last_name, 
      date_of_birth: moment(data.date_of_birth).format('YYYY-MM-DD'), 
      patient_address: data.patient_address, 
      zip: data.zip, 
      city: data.city, 
      city_id: data.city_id,
      state : data.state,
      patient_image: data.patient_image
    }

    const response = await API({
      api: `profile/edit_profile`,
      body: payload,
      type: 'POST'
    });

    if (response.is_error && response.is_error === 1) {
      dispatch(showToaster({...response, message: (response.original_override_text || response.original_text), type: ToasterType.Danger, status: true}))
    }
    else{
      dispatch(showToaster({ message: "Profile updated succesfully!", type: ToasterType.Success, status: true}))
    }
  };

  useEffect(() => {
    if(uploadThisImage){
      uploadImage();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadThisImage])
  

  const uploadImage = async () => {
    if(uploadThisImage){
      const profile_image = await base64ToBlob(uploadThisImage);
      console.log(profile_image);

      const response = await API({
        api: `profile/edit_profile_image`,
        body: { profile_image, patient_id },
        type: 'FORM'
      });
  
      if (response.is_error && response.is_error === 1) {
        dispatch(showToaster({...response, message: (response.original_override_text || response.override_text || response.original_text), type: ToasterType.Danger, status: true}))
      }
      else{
        setValue('patient_image', response.patient_image || '', { shouldValidate: false });
        // dispatch(showToaster({ message: "Profile updated succesfully!", type: ToasterType.Success, status: true}))
      }
    }
  }

  // Function to handle the image preview
  const handleImageChange = async (e: any) => {
    const file = e?.target?.files && e?.target.files.length ? e?.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadThisImage(reader.result);
        // uploadImage(reader.result);
      };
      reader.readAsDataURL(file);
      
      // await uploadImage();
    }
  };

  const getLocation = async (location_data: any) => {
    console.log('loc::',location_data?.formatted_address);
    
    const newCity = fetchAddress(location_data, "locality"); // City
    const newState = fetchAddress(location_data, "administrative_area_level_1"); // State
    const newZip = fetchAddress(location_data, "postal_code"); // ZIP

    if(newState){
      setValue('state', (newState || ''), { shouldValidate: true });
    }
    else{
      setValue('state', '', { shouldValidate: true });
    }
    setValue('zip', (newZip || ''), { shouldValidate: true });
    setValue('city', (newCity || ''), { shouldValidate: true });
    setValue('patient_address', (location_data?.formatted_address || ''), { shouldValidate: true });
  }

  return (
    <>
   <div className="px-5">
   <h4 className="fs-20 fw-600 m-0">My Profile</h4>
      <p className="fs-16 fw-400 m-0 mb-4">Update your photo and personal details here</p>

      <div className="d-flex align-items-center mb-3">
        <img
          src={ previewImage?.includes("placeholder")
          ? "../../assets/images/place-holder.png"
          : previewImage || "../../assets/images/place-holder.png"}
          alt="Avatar"
          className="rounded-circle hw-80 me-3 border"
          onClick={() => document.getElementById("patient-image-input")?.click()} // Trigger file input click
          style={{ cursor: "pointer" }}
        />
        <h4 className="fs-20 fw-600 m-0">{user?.patient_first_name} {user?.patient_last_name}</h4>
        <Form.Group className="ml-3 d-none">
          <Form.Control
            type="file"
            id="patient-image-input"
            src={previewImage}
            {...register("patient_image")}
            onChange={(e) => {
              handleImageChange(e);
              // register("patient_image").onChange(e); // Ensures file is registered
            }}
            isInvalid={!!errors.patient_image}
          />
          {/* <Form.Control.Feedback type="invalid">
            {(errors.patient_image?.message || '')}
          </Form.Control.Feedback> */}
        </Form.Group>
      </div>

      <Row className="pb-4 my-4 border-bottom">
        <Col  md={6}>
          <p  className="fs-14 fw-400 text-muted m-0 mb-1">
          Email:
          </p>
          <h4 className="fs-16 fw-500 m-0">
          {user?.patient_email}
          </h4>
        </Col>
        <Col  md={3}>
          <p className="fs-14 fw-400 text-muted m-0 mb-1"> 
          Phone Number:
          </p>
          <h4 className="fs-16 fw-500 m-0">
          {user?.patient_mobile_country_code}-{user?.patient_mobile}
          </h4>
        </Col>
        <Col md={3}>
          <p  className="fs-14 fw-400 text-muted m-0 mb-1">
          SSN Number:
          </p>
          <h4 className="fs-16 fw-500 m-0">
          *****{user?.patient_ssn}
          </h4>
        </Col>
      </Row>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
        <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">First Name</label>
          <input type="text" {...register('patient_first_name')} className="input_field w-100" placeholder="Enter First Name" />
          </Col>
          
          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">Last Name</label>
          <input type="text" {...register('patient_last_name')} className="input_field w-100" placeholder="Enter Last Name" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted w-100">Date of Birth</label>
          {/* <input type="text" {...register('date_of_birth')} className="input_field w-100" placeholder="Enter DOB" /> */}
          <Controller
          name="date_of_birth"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              selected={field.value ? new Date(field.value) : null}
              onChange={(date) => field.onChange(date?.toISOString().split('T')[0])}
              className="input_field w-100"
              placeholderText="Select DOB"
              dateFormat="yyyy-MM-dd" // Enforces YYYY-MM-DD format
            />
          )}
        />
          </Col>
          
          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted w-100">Address</label>
          {/* <input type="text" {...register('patient_address')} className="input_field w-100" placeholder="Enter Your Address" /> */}
          <ReactGoogleAutocomplete
            apiKey={getGoogleAPIKey()}
            onPlaceSelected={(place) => getLocation(place)}
            className="input_field w-100"
            defaultValue={user?.patient_address}
          />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">Zip Code</label>
          <input type="text" {...register('zip')} className="input_field w-100" placeholder="Enter Zip Code" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">City</label>
          <input type="text" {...register('city')} className="input_field w-100" placeholder="Enter City" />
          </Col>

          <Col md={6} className="mb-3">
          <label  className="fs-14 fw-500 text-muted">State</label>
          <input type="text" {...register('state')} className="input_field w-100" placeholder="Enter State" />
          </Col>
        </Row>


        <Button variant="secondary" type="submit" className="mnw-125">
          Save
        </Button>
      </Form>
   </div>
    </>
  );
};
